import { Fragment, useEffect, useState } from "react";
import Button from "../components/Button";
import { Routes, Route, useSearchParams } from "react-router-dom";
import APA from "./APA";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../state/slices/appSlice";
import { setUser } from "../state/slices/userSlice";
import Statistics from "./Statistics";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import AddContact from "./AddContact";
import AddCoEmprunteur from "./AddCoEmprunteur";
import AddRDV from "./AddRDV";
import ErrorPage from "./ErrorPage";
import EditRDV from "./EditRDV";
import EditContact from "./EditContact";
import EditDossier from "./EditDossier";
import EspaceCommercial from "./EspaceCommercial";
import Activitecommerciale from "./ActiviteCommerciale";
import EditCoEmprunteur from "./EditCoEmprunteur";
import ListRDV from "./ListRDV";
import AddAPA from "./AddAPA";
import AddStructure from "./AddStructure";
import ListAPA from "./ListAPA";
import ResultsAPA from "./ResultsAPA";
import EditFicheAPA from "./EditFicheAPA";
import AgendaCalendar from "./AgendaCalendar";
import DetailsOrganigram from "./DetailsOrganigram";
import ShareRequest from "./ShareRequest";
import EditStructure from "./EditStructure";
import JWTDecode from "../helpers/JWTDecode";
import Cookies from "js-cookie";
import AccesCommerciaux from "./AccesCommerciaux";
import AddAccesCommerciaux from "./AddAccesCommerciaux";
import EditAccesCommerciaux from "./EditAccesCommerciaux";
import ExtractStatistics from "./ExtractStatistics";
import AffectationDossier from "./AffectationDossier";
import AttribuerDossier from "./AttribuerDossier";
import Settings from "./Settings";
import CFCApi from "../apis";
import GestionBOStrucutres from "./GestionBOStructures";
import AddBOStructures from "./AddBOStructures";
import AddBOStrucutreType from "./AddBOStructureType";
import AddBOStructure from "./AddBOStructure";
import AddBOSubStructures from "./AddBOSubStructures";
import Nomenclatures from "./Nomenclatures";
import EditNomenclatures from "./EditNomenclatures";
import AddNomenclatures from "./AddNomenclatures";
import ListBOStructures from "./ListBOStructures";
import logout from "../helpers/logout";

export default function UserRoutes() {
  const dispatch = useDispatch();

  const [isAdmin, setIsAdmin] = useState<any>(null);

  const user: any = JWTDecode();

  const userState = useSelector((state: any) => state?.user?.data);

  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");

  useEffect(() => {
    dispatch(startLoading());
    setIsAdmin(user?.roles?.includes("ROLE_ADMIN"));
    // Seprating promise all into two promises to avoid skipping execution of then if one of the promises fails
    Promise.all([
      new Promise((resolve) =>
        CFCApi.get(`/users/${user.id}`)
          .then(resolve)
          .catch((error) => {
            console.error(error);
            resolve(null);
          })
      ),
    ])
      .then(([userResponse]) => {
        if (userResponse) {
          dispatch(setUser(userResponse));
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  }, []);

  const isGoogleAuthDisabled = JSON.parse(
    process.env.REACT_APP_GOOGLE_AUTH_DISABLED || "false"
  );
  if (!userState?.hasGoogleCalendar && !isGoogleAuthDisabled) {
    const protocol = window.location.protocol;
    const host = window.location.host;

    const redirect_url =
      process.env.REACT_APP_GOOGLE_REDIRECT || `${protocol}//${host}`;
    const google_agenda_endpoint = process.env.REACT_APP_GOOGLE_ENDPOINT;

    return (
      <div className="auth-overlay">
        <div className="overlay-container">
          {!google_agenda_endpoint ? (
            <p className="message-error">
              pas de google agenda endpoint ajouté
            </p>
          ) : (
            <Fragment>
              <div className="top-header-buttons">
                <Button
                  className="logout"
                  iconSize={20}
                  icon="logout"
                  to={() =>
                    logout(() => {
                      dispatch(setUser(null));
                    })
                  }
                  text="Déconnexion"
                />
              </div>

              {message && <p className="message-error">{message}</p>}
              <Button
                className="btn-auth"
                text="Veuillez autoriser l'agenda Google"
                onPress={() => {
                  window.location.href = `${google_agenda_endpoint}?user=${Cookies.get(
                    "token"
                  )}&redirect_url=${redirect_url}`;
                }}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/apa">
        <Route path="/apa" element={<APA />} />
        <Route path="results" element={<ResultsAPA />} />
        <Route path="edit-fiche-apa" element={<EditFicheAPA />} />
        <Route path="add-apa" element={<AddAPA />} />
        <Route path="add-structure" element={<AddStructure />} />
        <Route path="edit-structure" element={<EditStructure />} />
        <Route path="list-apa" element={<ListAPA />} />
        <Route path="organigram-details" element={<DetailsOrganigram />} />
      </Route>
      <Route path="list-structures" element={<ListBOStructures />} />
      {isAdmin ? (
        <Fragment>
          <Route
            path="/activite-commerciale"
            element={<Activitecommerciale />}
          />
          <Route path="/settings" element={<Settings />} />

          <Route path="/acces-commerciaux">
            <Route path="/acces-commerciaux" element={<AccesCommerciaux />} />
            <Route path="add" element={<AddAccesCommerciaux />} />
            <Route path="edit" element={<EditAccesCommerciaux />} />
          </Route>

          <Route path="/affectation-contact-dossier">
            <Route
              path="/affectation-contact-dossier"
              element={<AffectationDossier />}
            />
            <Route path="attribuer" element={<AttribuerDossier />} />
          </Route>
          <Route path="/nomenclatures">
            <Route path="/nomenclatures" element={<Nomenclatures />} />
            <Route path="add" element={<AddNomenclatures />} />
            <Route path="edit" element={<EditNomenclatures />} />
          </Route>
          <Route
            path="/gestion-des-structures"
            element={<GestionBOStrucutres />}
          />
          <Route path="/create-bo-strucutres" element={<AddBOStructures />} />
          <Route
            path="/create-bo-strucutre-type"
            element={<AddBOStrucutreType />}
          />
          <Route path="/create-bo-strucutre" element={<AddBOStructure />} />
          <Route
            path="/create-bo-sub-structures"
            element={<AddBOSubStructures />}
          />
        </Fragment>
      ) : (
        <Route path="/espace-commercial" element={<EspaceCommercial />} />
      )}
      <Route path="/statistics">
        <Route path="/statistics" element={<Statistics />} />
        <Route path="extract" element={<ExtractStatistics />} />
      </Route>
      <Route path="/agenda">
        <Route path="/agenda" element={<AgendaCalendar />} />
        <Route path="add-rdv" element={<AddRDV />} />
        <Route path="edit-rdv" element={<EditRDV />} />
        <Route path="list-rdv" element={<ListRDV />} />
      </Route>
      <Route path="/profile" element={<Profile />} />
      <Route path="/share-request" element={<ShareRequest />} />
      <Route path="/add-co-emprunteur" element={<AddCoEmprunteur />} />
      <Route path="/add-contact" element={<AddContact />} />
      <Route path="/edit-contact" element={<EditContact />} />
      <Route path="/add-co-emprunteur" element={<AddCoEmprunteur />} />
      <Route path="/edit-dossier" element={<EditDossier />} />
      <Route path="/fiche-history" element={<EditDossier isHistoy />} />
      <Route path="/edit-co-emprunteur" element={<EditCoEmprunteur />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

import PageWrapper from "../components/Layout/PageWrapper";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import Button from "../components/Button";
import useDownloads from "../hooks/useDownloads";

export default function Settings() {

  const { exportAll } = useDownloads()

  return (
    <PageWrapper>
      <Grid container>
        <Grid item xs={12} md={12} justifyContent="center">
          <div className="setting-actions">
            <h3>Paramètrage</h3>
            <Link className="btn" to="/acces-commerciaux">
              Création / modification COMPTES commerciaux
            </Link>
            <Link className="btn" to="/gestion-des-structures">
              gestion des structures
            </Link>
            <Link className="btn" to="/affectation-contact-dossier">
              modification D’affectation contact/dossier
            </Link>
            <Link className="btn" to="/nomenclatures">
              nomenclatures
            </Link>
            <Button onPress={exportAll} text="télécharger les données" />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

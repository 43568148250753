import { useEffect, useRef } from "react";
import Icon from "./Icon";
import { Link, useNavigate } from "react-router-dom";
import organigramMock from "../mocks/organigram";

type OrganigramDataProps = {
  data: any;
  showDetails?: boolean;
  onItemClick?: (id: number) => void;
  isEditableAPA?: boolean;
  isEditableStructure?: boolean;
  isRootLevel?: boolean;
  rootStructureId?: any;
};

const Organigram: React.FC<OrganigramDataProps> = ({
  data,
  showDetails = true,
  onItemClick,
  isEditableAPA,
  isEditableStructure,
  isRootLevel,
}) => {
  const navigate = useNavigate();

  const useFakeData = false;
  if (useFakeData) {
    data = organigramMock;
  }

  const handleItemClick = (id: number) => {
    if (onItemClick) {
      onItemClick(id);
    }
  };

  let leftHorizontal = data.horizontals["hydra:member"][0];
  let rightHorizontal = data.horizontals["hydra:member"][1];
  const bottoms = data.bottoms["hydra:member"];

  if (!leftHorizontal) {
    leftHorizontal = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
    data.horizontals["hydra:member"][0] = leftHorizontal;
  }

  if (!rightHorizontal) {
    rightHorizontal = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
    data.horizontals["hydra:member"][1] = rightHorizontal;
  }

  if (bottoms.length === 1) {
    data.bottoms["hydra:member"][1] = data.bottoms["hydra:member"][0];
    data.bottoms["hydra:member"][1].onlyChild = true;
    data.bottoms["hydra:member"][0] = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
    data.bottoms["hydra:member"][2] = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
  }

  if (bottoms.length === 1) {
    data.bottoms["hydra:member"][1] = data.bottoms["hydra:member"][0];

    data.bottoms["hydra:member"][0] = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
    data.bottoms["hydra:member"][2] = {
      toBeHidden: true,
      structure: "to be hidden",
      superStructure: "to be hidden",
    };
  }
  if (bottoms.length === 2) {
    data.bottoms["hydra:member"][2] = data.bottoms["hydra:member"][1];

    data.bottoms["hydra:member"][1] = null;
  }

  const horzEl1: any = useRef(null);
  const centerEl: any = useRef(null);
  const horzEl2: any = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!horzEl1.current || !horzEl2.current || !centerEl.current) {
        return;
      }

      horzEl1.current.style.height = "auto";
      horzEl2.current.style.height = "auto";
      centerEl.current.style.height = "auto";
      const maxHeight = Math.max(
        horzEl1.current.offsetHeight,
        centerEl.current.offsetHeight,
        horzEl2.current.offsetHeight
      );

      const style = { height: `${maxHeight}px` };
      horzEl1.current.style.height = style.height;
      horzEl2.current.style.height = style.height;
      centerEl.current.style.height = style.height;
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`organigram ${onItemClick ? "organigram-clickable" : ""}`}>
      <div className="organigram-wrapper">
        <div className="organigram-wrapper-inner">
          {data.top && (
            <div className="organigram-row organigram-row-top">
              <div
                className="organigram-col organigram-col-top"
                onClick={() =>
                  handleItemClick(data.top.id || data.top.structureId)
                }
              >
                <div className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.top.superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.top.structure}
                  </div>
                </div>
                {(data.top.name || data.top.job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.top.name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.top.job}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="organigram-row organigram-row-middle">
            {leftHorizontal && (
              <div
                className={`organigram-col organigram-col-horizontal organigram-col-middle${
                  leftHorizontal?.toBeHidden
                    ? " organigram-col-tobe-hidden"
                    : ""
                }`}
                onClick={() =>
                  handleItemClick(
                    data.horizontals["hydra:member"][0].id ||
                      data.horizontals["hydra:member"][0].structureId
                  )
                }
              >
                <div ref={horzEl1} className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.horizontals["hydra:member"][0].superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.horizontals["hydra:member"][0].structure}
                  </div>
                </div>
                {(data.horizontals["hydra:member"][0].name ||
                  data.horizontals["hydra:member"][0].job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.horizontals["hydra:member"][0].name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.horizontals["hydra:member"][0].job}
                    </div>
                  </div>
                )}
              </div>
            )}

            {data.center && (
              <div
                className={`organigram-col organigram-col-center organigram-col-middle${
                  leftHorizontal.toBeHidden && rightHorizontal.toBeHidden
                    ? " no-sibblings"
                    : ""
                }`}
              >
                <div
                  ref={centerEl}
                  className="organigram-rectangle organigram-rectangle-center"
                >
                  <div className="organigram-rectangle-title">
                    {data.center.superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.center.structure}
                  </div>
                  {isEditableStructure && (
                    <div className="organigram-rectangle-icon">
                      {isRootLevel && (
                        <Link
                          to="/create-bo-strucutre"
                          state={{
                            rootStructureId: data.center.structureId,
                          }}
                        >
                          <Icon icon="eye" size={14} />
                        </Link>
                      )}
                      {!isRootLevel && data?.center?.canEdit && (
                        <Link
                          to="/apa/edit-structure"
                          state={{
                            contactData: { id: data.center.structureId },
                          }}
                        >
                          <Icon icon="eye" size={14} />
                        </Link>
                      )}
                    </div>
                  )}
                </div>
                {(data.center.name || data.center.job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.center.name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.center.job}
                    </div>
                    {isEditableAPA && (
                      <div className="organigram-rectangle-icon">
                        <Link
                          to="/apa/edit-fiche-apa"
                          state={{ contactData: { id: data.center.id } }}
                        >
                          <Icon icon="eye" size={14} />
                        </Link>
                      </div>
                    )}
                  </div>
                )}
                {showDetails && (
                  <div className="organigram-details">
                    {(data?.center?.email || data?.center?.phone) && (
                      <div className="organigram-infos-wrapper">
                        <div className="organigram-rectangle organigram-rectangle-infos">
                          {data.center.email && (
                            <div>Email : {data.center.email}</div>
                          )}
                          {data.center.phone && (
                            <div>Téléphone : {data.center.phone}</div>
                          )}
                        </div>
                      </div>
                    )}
                    {data.center.apas &&
                      data.center.apas["hydra:member"] &&
                      data.center.apas["hydra:member"].length > 0 && (
                        <div className="organigram-siblings">
                          {data.center.apas["hydra:member"].map(
                            (item: any, index: number) => (
                              <div
                                className="organigram-siblings-item"
                                key={index}
                                onClick={() =>
                                  navigate(
                                    `/apa/organigram-details?id=${item.id}&type=apa`
                                  )
                                }
                              >
                                <div className="organigram-rectangle">
                                  <div className="organigram-siblings-name">
                                    {item.name}
                                  </div>
                                  <div className="organigram-rectangle-job">
                                    {item.job}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                  </div>
                )}

                {/* important border that gets added if there is at least one bottom */}
                {data.bottoms["hydra:member"][0] && (
                  <div className="border-bottom"></div>
                )}
              </div>
            )}

            {rightHorizontal && (
              <div
                className={`organigram-col organigram-col-horizontal organigram-col-middle${
                  rightHorizontal?.toBeHidden
                    ? " organigram-col-tobe-hidden"
                    : ""
                }`}
                onClick={() =>
                  handleItemClick(
                    data.horizontals["hydra:member"][1].id ||
                      data.horizontals["hydra:member"][1].structureId
                  )
                }
              >
                <div ref={horzEl2} className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.horizontals["hydra:member"][1].superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.horizontals["hydra:member"][1].structure}
                  </div>
                </div>
                {(data.horizontals["hydra:member"][1].name ||
                  data.horizontals["hydra:member"][1].job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.horizontals["hydra:member"][1].name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.horizontals["hydra:member"][1].job}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="organigram-row organigram-row-bottom">
            {data.bottoms["hydra:member"][0] && (
              <div
                className={`organigram-col organigram-col-bottom organigram-col-middle${
                  bottoms[0].toBeHidden ? " organigram-col-tobe-hidden" : ""
                }${
                  bottoms[0].onlyChild
                    ? " organigram-col-bottom-only-child"
                    : ""
                }`}
                onClick={() =>
                  handleItemClick(
                    data.bottoms["hydra:member"][0].id ||
                      data.bottoms["hydra:member"][0].structureId
                  )
                }
              >
                <div className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.bottoms["hydra:member"][0].superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.bottoms["hydra:member"][0].structure}
                  </div>
                </div>
                {(data.bottoms["hydra:member"][0].name ||
                  data.bottoms["hydra:member"][0].job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.bottoms["hydra:member"][0].name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.bottoms["hydra:member"][0].job}
                    </div>
                  </div>
                )}
              </div>
            )}

            {data.bottoms["hydra:member"][1] && (
              <div
                className={`organigram-col organigram-col-bottom organigram-col-bottom-horizontal${
                  bottoms[1].toBeHidden ? " organigram-col-tobe-hidden" : ""
                }
                `}
                onClick={() =>
                  handleItemClick(
                    data.bottoms["hydra:member"][1].id ||
                      data.bottoms["hydra:member"][1].structureId
                  )
                }
              >
                <div className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.bottoms["hydra:member"][1].superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.bottoms["hydra:member"][1].structure}
                  </div>
                </div>
                {(data.bottoms["hydra:member"][1].name ||
                  data.bottoms["hydra:member"][1].job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.bottoms["hydra:member"][1].name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.bottoms["hydra:member"][1].job}
                    </div>
                  </div>
                )}
              </div>
            )}

            {data.bottoms["hydra:member"][2] && (
              <div
                className={`organigram-col organigram-col-bottom organigram-col-bottom-horizontal${
                  bottoms[2].toBeHidden ? " organigram-col-tobe-hidden" : ""
                }`}
                onClick={() =>
                  handleItemClick(
                    data.bottoms["hydra:member"][2].id ||
                      data.bottoms["hydra:member"][2].structureId
                  )
                }
              >
                <div className="organigram-rectangle">
                  <div className="organigram-rectangle-title">
                    {data.bottoms["hydra:member"][2].superStructure}
                  </div>
                  <div className="organigram-rectangle-subtitle">
                    {data.bottoms["hydra:member"][2].structure}
                  </div>
                </div>
                {(data.bottoms["hydra:member"][2].name ||
                  data.bottoms["hydra:member"][2].job) && (
                  <div className="organigram-rectangle organigram-sub-rectangle">
                    <div className="organigram-rectangle-name">
                      {data.bottoms["hydra:member"][2].name}
                    </div>
                    <div className="organigram-rectangle-job">
                      {data.bottoms["hydra:member"][2].job}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organigram;

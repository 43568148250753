import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import FormGenerator, { Field } from "../components/Form";
import CFCApi from "../apis/";
import CFCApiHydra from "../apis/CFCApiHydra";
import useTrigger from "../hooks/useTrigger";
import Loading from "../components/Loading";
import useRDV from "../hooks/useRDV";

// TODO: update the field name (url) with the new name added in Back

export default function EditRDV() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {
    state: { appointmentData },
  } = useLocation();
  console.log(appointmentData);
  const { triggerModel, triggerError } = useTrigger();

  const { isLoading } = useSelector((state: any) => state.app);

  const { updateLieuField } = useRDV();

  const handleFormSubmit = async (values: {
    [key: string]: string | string[];
  }) => {
    if (values.apaLieu) {
      values.url = values.apaLieu;
      delete values.contact;
    }
    if (values.contactLieu) {
      values.url = values.contactLieu;
      delete values.apa;
    }
    delete values.contactLieu;
    delete values.apaLieu;

    try {
      dispatch(startLoading());

      const response = await CFCApi.patch(
        `/appointments/${appointmentData.id}`,
        values
      );
      dispatch(stopLoading());

      if (response) {
        triggerModel("success", "Prise de rendez-vous validée", () => {
          navigate("/");
          dispatch(setModel(null));
        });
        return;
      }
      triggerModel(
        "error",
        "An unexpected error occurred while creating RDV",
        () => dispatch(setModel(null))
      );
    } catch (error: any) {
      triggerError(error);
    }
  };

  const setDefaultValues = (formFieldValues: any) => {
    return new Promise((resolve, reject) => {
      try {
        let formFieldsUpdated = [...formFieldValues];

        if (appointmentData.type) {
          formFieldsUpdated[0].defaultValue = appointmentData.type["@id"];
        }
        if (appointmentData.apa) {
          formFieldsUpdated[1].defaultValue = appointmentData?.apa["@id"];
        }
        if (appointmentData.contact) {
          formFieldsUpdated[2].defaultValue = appointmentData?.contact["@id"];
        }
        if (appointmentData.appointmentDate) {
          formFieldsUpdated[3].defaultValue = new Date(
            appointmentData.appointmentDate
          );
        }
        if (appointmentData.startHour) {
          formFieldsUpdated[4].defaultValue = new Date(
            appointmentData.startHour
          );
        }
        if (appointmentData.endHour) {
          formFieldsUpdated[5].defaultValue = new Date(appointmentData.endHour);
        }
        if (appointmentData.guests) {
          formFieldsUpdated[6].defaultValue = appointmentData.guests;
        }
        if (appointmentData.url) {
          formFieldsUpdated[7].defaultValue = appointmentData.url;
        }
        setFormFields(formFieldsUpdated);

        resolve("resolved");
        setShowForm(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    const getFormDynamicValues = async () => {
      try {
        dispatch(startLoading());

        const typesResponse = await CFCApiHydra.get(
          "/appointment_types?hasParent=false&pagination=false"
        );
        const APAsRepsonse = await CFCApiHydra.get("/apas?pagination=false");
        const contactsRepsonse = await CFCApiHydra.get(
          "/contacts?all=true&pagination=false"
        );

        if (typesResponse["hydra:member"]) {
          let formFieldsUpdated = [...formFields];

          const filteredData: any = typesResponse["hydra:member"]
            .filter((ff: any) => ff.parentType?.name)
            .slice(0, 10);
          formFieldsUpdated[0].options = filteredData.map((opt: any) => ({
            label: `${opt.parentType.name} - ${opt.name}`,
            value: opt["@id"],
          }));

          formFieldsUpdated[7].parent = {
            depend: "type",
            values: [],
          };

          formFieldsUpdated[8].parent = {
            depend: "type",
            values: [],
          };
          typesResponse["hydra:member"].forEach((opt: any) => {
            if (opt.parentType?.name === "Terrain") {
              formFieldsUpdated[1].parent?.values?.push(opt["@id"]);
              formFieldsUpdated[7].parent?.values?.push(opt["@id"]);
            }
          });
          typesResponse["hydra:member"].forEach((opt: any) => {
            if (opt.parentType?.name === "Client") {
              formFieldsUpdated[2].parent?.values?.push(opt["@id"]);
              formFieldsUpdated[8].parent?.values?.push(opt["@id"]);
            }
          });

          setFormFields(formFieldsUpdated);
        } else {
          throw Error(
            "An unexpected error occurred while getting type de contact"
          );
        }

        if (APAsRepsonse["hydra:member"]) {
          let formFieldsUpdated = [...formFields];

          formFieldsUpdated[1].options = APAsRepsonse["hydra:member"]
            .slice(0, 10)
            .map((opt: any) => ({
              label: `${opt.firstName} ${opt.lastName}`,
              value: opt["@id"],
              lieu: opt?.structure?.address,
            }));
          setFormFields(formFieldsUpdated);
        } else {
          throw Error("An unexpected error occurred while getting APAs");
        }

        if (contactsRepsonse["hydra:member"]) {
          let formFieldsUpdated = [...formFields];

          const filteredData = contactsRepsonse["hydra:member"]
            .filter((cr: any) => cr.firstName && cr.lastName)
            .slice(0, 10);

          formFieldsUpdated[2].options = filteredData.map((opt: any) => ({
            label: `${opt.firstName} ${opt.lastName}`,
            value: opt["@id"],
            lieu: opt?.address,
          }));
          setFormFields(formFieldsUpdated);
        } else {
          throw Error("An unexpected error occurred while getting contacts");
        }
      } catch (error: any) {
        triggerModel("error", error.message || "Error", () =>
          dispatch(setModel(null))
        );
      } finally {
        await setDefaultValues(formFields);
        dispatch(stopLoading());
      }
    };

    getFormDynamicValues();
  }, []);

  const [showForm, setShowForm] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<Field[]>([
    {
      name: "type",
      type: "select",
      label: "Type de rendez-vous",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      handleChange: (value: any, formik: any) => {
        if (value.label.startsWith("Terrain")) {
          formFields[1].required = true;
          formFields[2].required = false;
          formFields[7].required = true;
          formFields[8].required = false;
        } else {
          formFields[1].required = false;
          formFields[2].required = true;
          formFields[7].required = false;
          formFields[8].required = true;
        }
        setFormFields(formFields);
      },
    },
    {
      name: "apa",
      type: "select",
      label: "APA",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "type",
        values: [],
      },
      handleChange: (value, formik) =>
        updateLieuField("apaLieu", value, formik),
      required: false,
    },
    {
      name: "contact",
      type: "select",
      label: "Contact / Dossier",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      parent: {
        depend: "type",
        values: [],
      },
      handleChange: (value, formik) =>
        updateLieuField("contactLieu", value, formik),
    },
    {
      name: "appointmentDate",
      type: "date",
      label: "Date",
      grid: {
        xs: 12,
        md: 6,
      },
      disablePast: true,
      required: true,
    },
    {
      name: "startHour",
      type: "hour",
      label: "Heure de début",
      grid: {
        xs: 6,
        md: 3,
      },
      required: true,
    },
    {
      name: "endHour",
      type: "hour",
      label: "Heure de fin",
      grid: {
        xs: 6,
        md: 3,
      },
      required: true,
    },
    {
      name: "guests",
      type: "tag",
      label: "Invités",
      defaultValue: appointmentData.guests,
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
    },
    {
      name: "apaLieu",
      type: "text",
      defaultValue: appointmentData.apa ? appointmentData.url : null,
      label: "Lieu",
      grid: {
        xs: 12,
        md: 6,
      },
      isReadOnly: true,
      required: true,
    },
    {
      name: "contactLieu",
      type: "text",
      defaultValue: appointmentData.contact ? appointmentData.url : null,
      label: "Lieu",
      grid: {
        xs: 12,
        md: 6,
      },
      isReadOnly: true,
      required: true,
    },
  ]);

  if (!appointmentData?.id) {
    return <h1> Aucune donnée RDV </h1>;
  }

  return (
    <PageWrapper>
      <h1>Modifier un rendez-vous</h1>
      {!isLoading && showForm ? (
        <FormGenerator
          fields={formFields}
          submitButtonText="Sauvegarder"
          widthReturn
          onSubmit={handleFormSubmit}
        />
      ) : (
        <Loading />
      )}
    </PageWrapper>
  );
}

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import PageWrapper from "../components/Layout/PageWrapper";
import InfoBox from "../components/InfoBox";
import InfoBoxAdmin from "../components/InfoBoxAdmin";
import Grid from "../components/Layout/Grid";
import Button from "../components/Button";
import Appointment from "../components/Appointment";
import Pagination from "../components/Pagination";
import CFCApi from "../apis";
import areObjectsEqual from "../helpers/checkObjectsEqual";
import moment from "moment";
import Icon from "../components/Icon";
import JWTDecode from "../helpers/JWTDecode";
import queryFormatDate from "../helpers/queryFormatDate";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user.data);
  const isCA = user?.title?.toLowerCase() === "responsiblebusiness";

  // Stats Variables
  const [volumeFinancedStat, setVolumeFinancedStat] = useState<any>(null);
  const [chiffresAffairesStat, setChiffresAffairesStat] = useState<any>(null);
  const [dossierEnCoursStat, setDossierEnCoursStat] = useState<any>(null);
  const [nombreDeContactStat, setNombreDeContactStat] = useState<any>(null);
  const [nombreDagencesStat, setNombreDagencesStat] = useState<any>(null);
  const [teamFigures, setTeamFigure] = useState<any>(null);
  const [monitoring, setMonitoring] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>([]);

  const [isSemain, setIsSemain] = useState<any>(false);

  const [currentAppointmentsData, setCurrentAppointementsData] = useState<any>(
    []
  );

  useEffect(() => {
    dispatch(startLoading());

    // TODO: replace with state from redux when roles gets added to user from the backend team
    const user: any = JWTDecode();

    const isAdmin = user?.roles?.includes("ROLE_ADMIN");
    setIsAdmin(isAdmin);
    const fetchDataStatus = async () => {
      try {
        const response = await CFCApi.get("/users/dashboard");
        setVolumeFinancedStat({
          label: "Volume financé annuel",
          value: `${response.fundedVolume} €`,
        });
        setChiffresAffairesStat({
          label: "Chiffres d'affaires mensuel",
          value: `${response.monthlyTurnover} €`,
        });
        setDossierEnCoursStat({
          label: "Dossiers en cours",
          value: [
            {
              label: "Total",
              value: response.prodFoldersCount + response.studyFoldersCount,
            },
            { label: "Accords", value: response.prodFoldersCount, dot: "full" },
            {
              label: "À l'étude",
              value: response.studyFoldersCount,
              dot: "border",
            },
          ],
        });
        setNombreDeContactStat({
          label: "Nombre de contacts en cours",
          value: [
            { label: "Contacts", value: response.contactsInProgressCount },
          ],
        });
        setNombreDagencesStat({
          label: "Nombre d'agences",
          value: [
            {
              label: "Agences",
              value: response.agenciesCount,
            },
            {
              label: "Actifs",
              value: response.activeAgenciesCount,
              dot: "full",
              dotColorClassName: "dot-orange",
            },
            {
              label: "Producteurs",
              value: response.prodAgenciesCount,
              dot: "full",
              dotColorClassName: "dot-green",
            },
          ],
        });
        if (response.adminDashboardData) {
          const adminDsahboardData = response.adminDashboardData;
          if (user.roles.includes("ROLE_ADMIN")) {
            setMonitoring({
              title: "Suivi de l’activité commerciale de l’équipe",
              boxes: [
                {
                  label: "Commerciaux sur terrain",
                  value: adminDsahboardData.commercialCount,
                },
                {
                  label: "Nombre de dossiers financés du mois",
                  value: adminDsahboardData.financedFolderCount,
                },
              ],
            });
          }

          setTeamFigure({
            title: "Chiffres clés de l’équipe",
            list: [
              {
                label: "RDVs terrain de la semaine",
                value: adminDsahboardData.rdvTerrainWeekCount,
              },
              {
                label: "Nombre de réunions de la semaine",
                value: adminDsahboardData.rdvWeekAvg,
              },
              {
                label: "Dossiers envoyés ce mois-ci",
                value: adminDsahboardData.passContactToFolderThisMonthCount,
              },
              {
                label: "Contacts créés ce mois-ci",
                value: adminDsahboardData.createdContactThisMonthCount,
              },
            ],
          });
        }
      } catch (err: any) {
        setModel({
          type: "error",
          content: err.message,
          onClose: () => dispatch(setModel(null)),
        });
      } finally {
        dispatch(stopLoading());
      }
    };

    fetchDataStatus();
  }, []);

  return (
    <PageWrapper>
      <Grid container direction="row-reverse" columnSpacing={4}>
        <Grid
          xs={12}
          md={6}
          sx={{
            my: {
              md: 0,
              sm: 2,
            },
            px: {
              xs: 0,
            },
          }}
        >
          <div id="stats">
            <Grid container spacing={2}>
              {volumeFinancedStat && (
                <Grid className="infoCol" xs={6}>
                  <InfoBox stat={volumeFinancedStat} />
                </Grid>
              )}

              {chiffresAffairesStat && (
                <Grid className="infoCol" xs={6}>
                  <InfoBox stat={chiffresAffairesStat} />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2}>
              {dossierEnCoursStat && (
                <Grid className="infoCol" xs={12}>
                  <InfoBox stat={dossierEnCoursStat} />
                </Grid>
              )}

              {nombreDeContactStat && (
                <Grid className="infoCol" xs={12}>
                  <InfoBox stat={nombreDeContactStat} />
                </Grid>
              )}

              {!isCA && teamFigures && (
                <Grid className="infoCol" xs={12}>
                  <InfoBoxAdmin stateData={teamFigures} />
                </Grid>
              )}

              {monitoring && (
                <Grid className="infoCol" xs={12}>
                  <InfoBoxAdmin stateData={monitoring} />
                </Grid>
              )}

              <Grid className="infoCol" xs={12}>
                {nombreDagencesStat && <InfoBox stat={nombreDagencesStat} />}{" "}
              </Grid>
            </Grid>
          </div>
          <div className="btn-wrapper">
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Button
                  text="Ajouter un contact"
                  icon="user-plus"
                  size="sm"
                  block
                  to={() => navigate("/add-contact")}
                />
              </Grid>
              <Grid xs={6}>
                <Button
                  text="Ajouter un rdv"
                  icon="calendar"
                  size="sm"
                  block
                  to={() => navigate("/agenda/add-rdv")}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          xs={12}
          md={5}
          sx={{
            my: {
              md: 0,
              sm: 2,
            },
            px: {
              xs: 0,
            },
          }}
        >
          {isAdmin && <h2>Rendez-vous, rappels et notifications</h2>}
          <div className="notifications">
            {notifications.map((notification: any) => (
              <Link
                className="notification"
                to="/share-request"
                state={{ contactData: notification }}
              >
                <div className="notification-title">
                  <span>{notification.title}</span>
                </div>
                <div className="notification-body">
                  <Icon icon="user" className="icon-user" size={16} />
                  <span>{`${notification.sourceUser.firstName} ${notification.sourceUser.lastName}`}</span>
                  <Icon
                    icon="arrow-right"
                    className="icon-arrow-right"
                    size={14}
                  />
                </div>
              </Link>
            ))}

            {isAdmin && (
              <Pagination
                apiData="/users/my-notifications"
                getCurrentItems={(current) => {
                  if (!areObjectsEqual(current, notifications)) {
                    setNotifications(current);
                  }
                }}
              />
            )}
          </div>

          <div className="appointments">
            <div className="head">
              {!isAdmin && <h2>Rendez-vous et rappels</h2>}
              <div className="filters-wrapper">
                <ul>
                  <li className={!isSemain ? "active" : ""}>
                    <button id="jour" onClick={() => setIsSemain(false)} />
                    <label htmlFor="jour">Jour</label>
                  </li>
                  <li className={isSemain ? "active" : ""}>
                    <label htmlFor="semaine">Semaine</label>
                    <button id="semaine" onClick={() => setIsSemain(true)} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {currentAppointmentsData?.length > 0 ? (
            currentAppointmentsData.map((appointment: any, index: number) => (
              <Appointment key={index} appointment={appointment} />
            ))
          ) : (
            <p className="message">aucun rendez-vous trouvé</p>
          )}

          <Pagination
            apiData={
              !isSemain
                ? `/appointments?${queryFormatDate(
                    moment().format("DD-MM-YYYY")
                  )}`
                : `/appointments?ofWeek=true`
            }
            getCurrentItems={(current) => {
              if (!areObjectsEqual(current, currentAppointmentsData)) {
                setCurrentAppointementsData(current);
              }
            }}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

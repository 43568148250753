import { useState, useEffect } from "react";
import CFCApi from "../apis/";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startLoading, stopLoading, setModel } from "../state/slices/appSlice";
import { Field, OperationNames } from "../components/Form";
import extractIdFromStrings from "../helpers/extractIdFromStrings";
import { setAPAFiled, setTypeField } from "../helpers/setFileds";
import useTrigger from "./useTrigger";
import CFCApiHydra from "../apis/CFCApiHydra";
import route_links from "../shared/route_links";

const useContact = (
  contactData?: any,
  coEmprunteurData?: any,
  isCoEmpEdit?: boolean | null,
  attachmentHanlder?: () => void
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { triggerModel, triggerError } = useTrigger();

  const [attachmentValues, setAttachmentValues] = useState<any>(null);

  const [savedUserData, setSaveUserData] = useState<any>(null);
  const [showForm, setShowForm] = useState<boolean>(false);

  const autoFillSite = (val: string, trgFormFields: any, trgIndex: number) => {
    if (trgFormFields[trgIndex]?.options) {
      const field: any = trgFormFields[trgIndex].options.find(
        (opt: any) => opt.value === val
      );
      return {
        label: field?.structure?.name || "",
        value: field?.structure["@id"] || null,
      };
    } else {
      return { label: null, value: null };
    }
  };

  const [coEmprunteurFields] = useState<any>([
    {
      name: "coBorrowerCivility",
      type: "select",
      label: "Civilité",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      defaultValue: coEmprunteurData?.coBorrowerCivility,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerFirstName",
      type: "text",
      label: "Nom du client",
      defaultValue: coEmprunteurData?.coBorrowerFirstName,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerLastName",
      type: "text",
      label: "Prénom du client",
      defaultValue: coEmprunteurData?.coBorrowerLastName,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerBirthDate",
      type: "date",
      label: "Date de naissance",
      defaultValue: coEmprunteurData?.coBorrowerBirthDate,
      disableFuture: true,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerPhone",
      type: "tel",
      label: "Téléphone portable",
      defaultValue: coEmprunteurData?.coBorrowerPhone,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerEmail",
      type: "email",
      label: "Email",
      defaultValue: coEmprunteurData?.coBorrowerEmail,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "coBorrowerLandline",
      type: "tel",
      label: "Téléphone fixe",
      defaultValue: coEmprunteurData?.coBorrowerLandline,
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
  ]);

  const [contactFormFields, setContactFormFields] = useState<any>([
    {
      name: "type",
      type: "select",
      label: "Type de contact",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "apa",
      type: "select",
      label: "APA commercial",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "site",
      type: "abstract",
      label: "Site",
      isReadOnly: true,
      required: true,
      grid: {
        xs: 12,
        md: 6,
      },
      trigger: {
        name: "apa",
        triggerFunc: (val: any) => autoFillSite(val, contactFormFields, 1),
      },
    },
    {
      name: "civility",
      type: "select",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      label: "Civilité",
      required: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Nom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "birthDate",
      type: "date",
      disableFuture: true,
      label: "Date de naissance",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "maritalStatus",
      type: "select",
      options: [
        { label: "Célibataire", value: "Célibataire" },
        { label: "Concubin(-e)", value: "Concubin(-e)" },
        { label: "Divorcé(-e)", value: "Divorcé(-e)" },
        { label: "Marié(-e)", value: "Marié(-e)" },
        { label: "Pacsé(-e)", value: "Pacsé(-e)" },
        { label: "Veuf(-ve)", value: "Veuf(-ve)" },
        { label: "Séparé(-e)", value: "Séparé(-e)" },
      ],
      label: "Situation matrimoniale",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone portable",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "landLine",
      type: "tel",
      label: "Téléphone fixe",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
    },
    {
      name: "co_borrower",
      type: "link_input",
      data: coEmprunteurData,
      label: "Co emprunteur",
      grid: {
        xs: 12,
        md: 6,
      },
      url: "/add-co-emprunteur",
      parent: {
        depend: "maritalStatus",
        values: ["Marié(-e)", "Concubin(-e)", "Pacsé(-e)"],
      },
    },
  ]);

  const [editContactFormFields, setEditContactFormFields] = useState<Field[]>([
    {
      name: "type",
      type: "select",
      label: "Type de contact",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "status",
      type: "select",
      label: "Statut",
      highlight: true,
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "apa",
      type: "select",
      label: "APA commercial",
      options: [],
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "site",
      type: "abstract",
      label: "Site",
      isReadOnly: true,
      required: true,
      grid: {
        xs: 12,
        md: 6,
      },
      trigger: {
        name: "apa",
        triggerFunc: (val: any) => autoFillSite(val, editContactFormFields, 2),
      },
      isEditable: true,
    },
    {
      name: "dateCreation",
      type: "date",
      disableFuture: true,
      label: "Date de création",
      grid: {
        xs: 12,
        md: 6,
      },
      required: false,
      isEditable: false,
      isReadOnly: true,
    },
    {
      name: "civility",
      type: "select",
      options: [
        { label: "Mr.", value: "Mr." },
        { label: "Mme.", value: "Mme." },
      ],
      grid: {
        xs: 12,
        md: 6,
      },
      label: "Civilité",
      required: true,
      isEditable: true,
    },
    {
      name: "lastName",
      type: "text",
      label: "Nom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "firstName",
      type: "text",
      label: "Prénom du client",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "birthDate",
      type: "date",
      disableFuture: true,
      label: "Date de naissance",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "maritalStatus",
      type: "select",
      options: [
        { label: "Célibataire", value: "Célibataire" },
        { label: "Concubin(-e)", value: "Concubin(-e)" },
        { label: "Divorcé(-e)", value: "Divorcé(-e)" },
        { label: "Marié(-e)", value: "Marié(-e)" },
        { label: "Pacsé(-e)", value: "Pacsé(-e)" },
        { label: "Veuf(-ve)", value: "Veuf(-ve)" },
        { label: "Séparé(-e)", value: "Séparé(-e)" },
      ],
      label: "Situation matrimoniale",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "phone",
      type: "tel",
      label: "Téléphone portable",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "landLine",
      type: "tel",
      label: "Téléphone fixe",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "address",
      type: "text",
      label: "Adresse",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "zipCode",
      type: "text",
      label: "Code postal",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "city",
      type: "text",
      label: "Ville",
      grid: {
        xs: 12,
        md: 6,
      },
      required: true,
      isEditable: true,
    },
    {
      name: "co_borrower",
      type: "link_input",
      data: coEmprunteurData,
      label: "Co emprunteur",
      grid: {
        xs: 12,
        md: 6,
      },
      url: "/edit-co-emprunteur",
      concatData: true,
      parent: {
        depend: "maritalStatus",
        values: ["Marié(-e)", "Concubin(-e)", "Pacsé(-e)"],
      },
    },
    {
      name: "attachment",
      type: "upload",
      label: "Pièces jointes",
      grid: {
        xs: 12,
        md: 6,
      },
      tooltip: "Carte d'identité, Passeport ou Permis",
      uploadAction: {
        handler: attachmentHanlder,
      },
    },
    {
      name: "comment",
      type: "textarea",
      label: "Commentaires ",
      grid: {
        xs: 12,
        md: 6,
      },
      isEditable: true,
    },
    {
      name: "validation_hint",
      type: "hint",
      label: "Valider pour pouvoir convertir le contact en dossier",
      grid: {
        xs: 12,
        md: 12,
      },
      parent: {
        depend: "status",
        values: ["send_bo"],
      },
    },
    {
      name: "duration",
      type: "number",
      label: "Durée en mois",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement"],
      },
      required: true,
      isEditable: true,
    },
    {
      name: "duration",
      type: "number",
      label: "Durée en mois",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["remote_study"],
      },
      required: false,
      isEditable: true,
    },
    {
      name: "externalApaFolderNumber",
      type: "text",
      label: "Numéro dossier externe-APA",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo"],
      },
      required: true,
      isEditable: true,
    },
    {
      name: "externalApaPackagerNumber",
      type: "text",
      label: "Numéro dossier externe packageur",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo"],
      },
      required: true,
      isEditable: true,
    },
    {
      name: "grossTurnover",
      type: "text",
      label: "Chiffre d’affaires brut",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement"],
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "grossTurnover",
      type: "text",
      label: "Chiffre d’affaires brut",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["remote_study"],
      },
      required: false,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "retrocessionAmount",
      type: "text",
      label: "Rétrocession",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement", "remote_study"],
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "netTurnover",
      type: "text",
      label: "Chiffre d’affaires net",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement", "remote_study"],
      },
      operation: {
        name: OperationNames.subtraction,
        targetField: "netTurnover",
        fields: ["grossTurnover", "retrocessionAmount"],
      },
      required: false,
      isReadOnly: true,
      prefix: "€",
    },
    {
      name: "expirationEarnings",
      type: "text",
      label: "Gains sur échéance",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo"],
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "amount",
      type: "text",
      label: "Montant",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement"],
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "amount",
      type: "text",
      label: "Montant",
      grid: {
        xs: 12,
        md: 6,
      },
      parent: {
        depend: "status",
        values: ["remote_study"],
      },
      required: false,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "treasury",
      type: "text",
      label: "Trésorerie",
      grid: {
        xs: 12,
        md: 6,
      },

      parent: {
        depend: "status",
        values: ["send_bo", "home_study", "agreement"],
      },
      required: true,
      isEditable: true,
      prefix: "€",
    },
    {
      name: "treasury",
      type: "text",
      label: "Trésorerie",
      grid: {
        xs: 12,
        md: 6,
      },

      parent: {
        depend: "status",
        values: ["remote_study"],
      },
      required: false,
      isEditable: true,
      prefix: "€",
    },
  ]);

  const [editCoEmprunteurFields, setEditCoEmprunteurFields] =
    useState<any>(null);

  const updateCoEmprunteur = async (
    contactDataId: string,
    contact?: any,
    values?: any
  ) => {
    if (!values) {
      return;
    }

    let modifiedId = contact
      ? contact["@id"].replace("/api", "")
      : contactDataId.replace("/api", "");

    dispatch(startLoading());
    try {
      const response = await CFCApi.patch(`${modifiedId}/co-borrower`, values);

      dispatch(stopLoading());
      if (response) {
        triggerModel(
          "success",
          contact
            ? "Contact et co-coemprunteur créés avec succès"
            : "mise à jour réussie",

          () => {
            dispatch(setModel(null));
            navigate(route_links.espace.url);
          }
        );
        return;
      }
      triggerModel(
        "error",
        "Une erreur inattendue s'est produite lors de la création du co-emprunteur.",
        () => {
          dispatch(setModel(null));
        }
      );
    } catch (error: any) {
      triggerError(error);
    }
  };

  const updateContact = async (values: any) => {
    try {
      dispatch(startLoading());

      const contactDataValues = { ...values };
      delete contactDataValues["@context"];
      delete contactDataValues["@id"];
      delete contactDataValues["@type"];
      delete contactDataValues.attachment;
      delete contactDataValues.attachments;
      delete contactDataValues.co_borrower;
      delete contactDataValues.coBorrowerBirthDate;
      delete contactDataValues.coBorrowerEmail;
      delete contactDataValues.coBorrowerPhone;
      delete contactDataValues.coBorrowerCivility;
      delete contactDataValues.coBorrowerLandline;
      delete contactDataValues.coBorrowerLastName;
      delete contactDataValues.coBorrowerFirstName;

      const response = await CFCApi.patch(
        `/contacts/${extractIdFromStrings(contactData["@id"])}`,
        contactDataValues
      );

      if (attachmentValues) {
        await CFCApi.post(
          `/contacts/${extractIdFromStrings(contactData["@id"])}/attachments`,
          attachmentValues
        );
      }

      if (response) {
        updateCoEmprunteur(response, values);
        return;
      }
      dispatch(stopLoading());
      dispatch(
        setModel({
          type: "error",
          content:
            "Une erreur inattendue s'est produite lors de la création du contact.",
          onClose: () => dispatch(setModel(null)),
        })
      );
    } catch (error: any) {
      triggerError(error);
    }
  };

  const getFormDynamicValues = async (
    trgFormFields: any,
    setTrgFormField: any
  ) => {
    try {
      dispatch(startLoading());
      Promise.all([
        CFCApiHydra.get("/types?pagination=false&hasParent=false"),
        CFCApiHydra.get("/apas?type=commercial&withRep=true&pagination=false"),
        CFCApiHydra.get(`/contacts/${extractIdFromStrings(contactData.id)}`),
      ])
        .then(([typesResponse, APAResponse, ContactResponse]) => {
          if (
            !typesResponse["hydra:member"] ||
            !APAResponse["hydra:member"] ||
            !ContactResponse
          ) {
            throw new Error(
              "Une erreur inattendue s'est produite lors de la récupération des données dynamiques du formulaire."
            );
          }

          let formFieldsUpdated = trgFormFields;

          setTypeField(formFieldsUpdated, typesResponse, 0);
          setAPAFiled(formFieldsUpdated, APAResponse, 2);

          // update CoBorrower name field in case it's not saved with Contact
          if (
            coEmprunteurData?.coBorrowerFirstName &&
            coEmprunteurData?.coBorrowerLastName
          ) {
            formFieldsUpdated[16].defaultValue = `${coEmprunteurData.coBorrowerFirstName} ${coEmprunteurData.coBorrowerLastName}`;
          }
          setSaveUserData(ContactResponse);

          formFieldsUpdated[16].url = `/edit-co-emprunteur?contactId=${extractIdFromStrings(
            ContactResponse["@id"]
          )}`;

          for (let key in ContactResponse) {
            switch (key) {
              case "type":
                formFieldsUpdated[0].defaultValue = ContactResponse[key]["@id"];
                break;
              case "status":
                formFieldsUpdated[1].defaultValue = ContactResponse[key];
                switch (ContactResponse[key]) {
                  case "making_contact":
                    formFieldsUpdated[1].options = [
                      {
                        label: "Prise de contact",
                        value: "making_contact",
                      },
                      { label: "Faisabilité", value: "feasibility" },
                      { label: "Injoignable", value: "unreachable" },
                    ];
                    break;
                  case "feasibility":
                    formFieldsUpdated[1].options = [
                      { label: "Faisabilité", value: "feasibility" },
                      { label: "étude à domicile", value: "home_study" },
                      { label: "étude à distance", value: "remote_study" },
                      {
                        label: "Sans suite client",
                        value: "without_customer_followup",
                      },
                      {
                        label: "Sans suite hors normes",
                        value: "without_extraordinary_followup",
                      },
                    ];
                    break;
                  case "remote_study":
                    formFieldsUpdated[1].options = [
                      { label: "étude à distance", value: "remote_study" },
                      {
                        label: "Sans suite client",
                        value: "without_customer_followup",
                      },
                      { label: "Envoi Bo", value: "send_bo" },
                    ];
                    break;
                  case "home_study":
                    formFieldsUpdated[1].options = [
                      { label: "étude à domicile", value: "home_study" },
                      {
                        label: "Sans suite client",
                        value: "without_customer_followup",
                      },
                      { label: "Envoi Bo", value: "send_bo" },
                    ];
                    break;
                  case "send_bo":
                    formFieldsUpdated[33].defaultValue = "hello world";
                    formFieldsUpdated[1].options = [
                      { label: "Envoi Bo", value: "send_bo" },
                      {
                        label: "Sans suite client",
                        value: "without_customer_followup",
                      },
                      { label: "Refus bo", value: "rejected_bo" },
                      { label: "Refus banque", value: "rejected_bank" },
                      { label: "Accord", value: "agreement" },
                    ];
                    break;
                  case "agreement":
                    formFieldsUpdated[1].options = [
                      { label: "Accord", value: "agreement" },
                      {
                        label: "Sans suite client",
                        value: "without_customer_followup",
                      },
                      { label: "Financé", value: "financed" },
                      { label: "Refus banque", value: "rejected_bank" },
                    ];
                    break;
                  default:
                    formFieldsUpdated[1].options = [];
                    break;
                }
                break;
              case "apa":
                formFieldsUpdated[2].defaultValue = ContactResponse[key]["@id"];
                break;
              case "site":
                formFieldsUpdated[3].defaultValue = ContactResponse[key]["@id"];
                break;
              case "createdAt":
                formFieldsUpdated[4].defaultValue = new Date(
                  ContactResponse[key]
                );
                break;
              case "civility":
                formFieldsUpdated[5].defaultValue = ContactResponse[key];
                break;
              case "lastName":
                formFieldsUpdated[6].defaultValue = ContactResponse[key];
                break;
              case "firstName":
                formFieldsUpdated[7].defaultValue = ContactResponse[key];
                break;
              case "birthDate":
                formFieldsUpdated[8].defaultValue = new Date(
                  ContactResponse[key]
                );
                break;
              case "maritalStatus":
                formFieldsUpdated[9].defaultValue =
                  contactData?.maritalStatus || ContactResponse[key];
                break;
              case "phone":
                formFieldsUpdated[10].defaultValue = ContactResponse[key];
                break;
              case "email":
                formFieldsUpdated[11].defaultValue = ContactResponse[key];
                break;
              case "landLine":
                formFieldsUpdated[12].defaultValue = ContactResponse[key];
                break;
              case "address":
                formFieldsUpdated[13].defaultValue = ContactResponse[key];
                break;
              case "zipCode":
                formFieldsUpdated[14].defaultValue = ContactResponse[key];
                break;
              case "city":
                formFieldsUpdated[15].defaultValue = ContactResponse[key];
                break;
              case "coBorrowerFirstName":
                formFieldsUpdated[16].defaultValue = coEmprunteurData
                  ? `${coEmprunteurData.coBorrowerFirstName} ${coEmprunteurData.coBorrowerLastName}`
                  : `${ContactResponse[key]} ${ContactResponse["coBorrowerLastName"]}`;
                break;
              case "attachments":
                formFieldsUpdated[17].defaultValue = `${ContactResponse[key].length} files`;
                break;

              case "comment":
                formFieldsUpdated[18].defaultValue = null;
                break;
              case "comments":
                formFieldsUpdated[18].details = ContactResponse[key];
                break;
              case "duration":
                formFieldsUpdated[20].defaultValue = ContactResponse[key];
                formFieldsUpdated[21].defaultValue = ContactResponse[key];
                break;
              case "externalApaFolderNumber":
                formFieldsUpdated[22].defaultValue = ContactResponse[key];
                break;
              case "externalApaPackagerNumber":
                formFieldsUpdated[23].defaultValue = ContactResponse[key];
                break;
              case "grossTurnover":
                formFieldsUpdated[24].defaultValue = ContactResponse[key];
                formFieldsUpdated[25].defaultValue = ContactResponse[key];
                break;
              case "retrocessionAmount":
                formFieldsUpdated[26].defaultValue = ContactResponse[key];
                break;
              case "netTurnover":
                formFieldsUpdated[27].defaultValue = ContactResponse[key];
                break;
              case "expirationEarnings":
                formFieldsUpdated[28].defaultValue = ContactResponse[key];
                break;
              case "amount":
                formFieldsUpdated[29].defaultValue = ContactResponse[key];
                formFieldsUpdated[30].defaultValue = ContactResponse[key];
                break;
              case "treasury":
                formFieldsUpdated[31].defaultValue = ContactResponse[key];
                formFieldsUpdated[32].defaultValue = ContactResponse[key];
                break;
              default:
            }
          }
          setTrgFormField(formFieldsUpdated);
          setShowForm(true);
        })
        .catch((error: any) => {
          triggerError(error);
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    } catch (error: any) {
      dispatch(stopLoading());
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    }
  };

  const getFormInitialDynamicValues = async (
    trgFormFields: any,
    setTrgFormField: any,
    coEmprunteurIndex?: number
  ) => {
    try {
      dispatch(startLoading());
      Promise.all([
        CFCApiHydra.get("/types?pagination=false&hasParent=false"),
        CFCApiHydra.get("/apas?type=commercial&withRep=true&pagination=false"),
      ])
        .then(([typesResponse, APAResponse]) => {
          if (!typesResponse["hydra:member"] || !APAResponse["hydra:member"]) {
            throw new Error(
              "Une erreur inattendue s'est produite lors de la récupération des données dynamiques du formulaire."
            );
          }

          const formFieldsUpdated = trgFormFields;

          setTypeField(formFieldsUpdated, typesResponse, 0);

          setAPAFiled(formFieldsUpdated, APAResponse, 1);

          if (coEmprunteurData && typeof coEmprunteurIndex === "number") {
            if (formFieldsUpdated[coEmprunteurIndex]) {
              formFieldsUpdated[
                coEmprunteurIndex
              ].defaultValue = `${coEmprunteurData.coBorrowerFirstName} ${coEmprunteurData.coBorrowerLastName}`;
            } else {
              dispatch(
                setModel({
                  type: "error",
                  content:
                    "Une erreur est survenue lors de la récupération des informations du co-emprunteur",
                  onClose: () => dispatch(setModel(null)),
                })
              );
            }
          }
          setTrgFormField(formFieldsUpdated);
        })
        .catch((error: any) => {
          triggerError(error);
        })
        .finally(() => {
          dispatch(stopLoading());
        });
    } catch (error: any) {
      dispatch(stopLoading());
      dispatch(
        setModel({
          type: "error",
          content: error.message,
          onClose: () => dispatch(setModel(null)),
        })
      );
    }
  };

  const updateCoEmprunteurEditForm = async () => {
    try {
      dispatch(startLoading);
      const response = await CFCApiHydra.get(
        `/contacts/${extractIdFromStrings(contactData.id || contactData["id"])}`
      );

      setEditCoEmprunteurFields([
        {
          name: "coBorrowerCivility",
          type: "select",
          label: "Civilité",
          options: [
            { label: "Mr.", value: "Mr." },
            { label: "Mme.", value: "Mme." },
          ],
          defaultValue:
            coEmprunteurData?.coBorrowerCivility ||
            response?.coBorrowerCivility,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerLastName",
          type: "text",
          label: "Nom du client",
          defaultValue:
            coEmprunteurData?.coBorrowerLastName ||
            response?.coBorrowerLastName,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerFirstName",
          type: "text",
          label: "Prénom du client",
          defaultValue:
            coEmprunteurData?.coBorrowerFirstName ||
            response?.coBorrowerFirstName,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerBirthDate",
          type: "date",
          disableFuture: true,
          label: "Date de naissance",
          defaultValue:
            coEmprunteurData?.coBorrowerBirthDate ||
            response?.coBorrowerBirthDate
              ? new Date(
                  coEmprunteurData?.coBorrowerBirthDate ||
                    response?.coBorrowerBirthDate
                )
              : null,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerPhone",
          type: "tel",
          label: "Téléphone portable",
          defaultValue:
            coEmprunteurData?.coBorrowerPhone || response?.coBorrowerPhone,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerEmail",
          type: "email",
          label: "Email",
          defaultValue:
            coEmprunteurData?.coBorrowerEmail || response?.coBorrowerEmail,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
        {
          name: "coBorrowerLandline",
          type: "tel",
          label: "Téléphone fixe",
          defaultValue:
            coEmprunteurData?.coBorrowerLandline ||
            response?.coBorrowerLandline,
          grid: {
            xs: 12,
            md: 6,
          },
          required: true,
        },
      ]);

      dispatch(stopLoading);
      if (!response) {
        throw new Error("Error while gitting contact");
      }
    } catch (error) {
      triggerError(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (isCoEmpEdit) {
        updateCoEmprunteurEditForm();
        return;
      }
      if (contactData && (contactData["@id"] || contactData["id"])) {
        await getFormDynamicValues(
          editContactFormFields,
          setEditContactFormFields
        );
        return;
      }
      await getFormInitialDynamicValues(
        contactFormFields,
        setContactFormFields,
        14
      );
    })();
  }, []);

  return {
    contactFormFields,
    editContactFormFields,
    setEditContactFormFields,
    coEmprunteurFields,
    editCoEmprunteurFields,
    savedUserData,
    updateCoEmprunteur,
    updateContact,
    getFormDynamicValues,
    attachmentValues,
    setAttachmentValues,
    showForm,
  };
};

export default useContact;

import axios, { CancelTokenSource } from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookie from "js-cookie";
import { setHeaderInCondition, handleUnAutorized } from "../utils/requests";

class APIManager {
  baseUrl: string;
  isHydra?: boolean;
  pendingReqs: {
    id: string;
    url: string;
    isCancelable: boolean;
    cancelTokenSource: CancelTokenSource;
  }[];
  constructor(baseUrl: string, isHydra?: boolean) {
    this.baseUrl = 'https://cfc-back-test.lagentx.tech/api';
    this.isHydra = isHydra;
    this.pendingReqs = [];
    this.cancelReqs = this.cancelReqs.bind(this);
  }

  cancelReqs(reason?: string) {
    const pendingReqs = [...this.pendingReqs];
    const toCancel = pendingReqs.filter((pr) => pr.isCancelable);
    this.pendingReqs = pendingReqs.filter((pr) => !pr.isCancelable);
    toCancel.forEach(({ cancelTokenSource }) => {
      const reasonEnd = reason ? ` by ${reason}` : " !";
      cancelTokenSource.cancel(`Request canceled${reasonEnd}`);
    });
  }

  async request(
    endpoint: string,
    method: string,
    body: any = null,
    isCancelable: boolean = true
  ) {
    const url = this.baseUrl + endpoint;
    let headers = {
      "Content-Type": "application/json",
    };

    headers = setHeaderInCondition(headers, this.isHydra, "Accept", "*/*");
    headers = setHeaderInCondition(
      headers,
      method === "PATCH",
      "Content-Type",
      "application/merge-patch+json"
    );

    const token = Cookie.get("token");
    headers = setHeaderInCondition(
      headers,
      token,
      "Authorization",
      `Bearer ${token}`
    );

    const switchUser = Cookie.get("switchuser");
    headers = setHeaderInCondition(
      headers,
      switchUser,
      "X-Switch-User",
      `${switchUser}`
    );

    const cancelTokenSource = axios.CancelToken.source();
    const cancleTokenPayload = {
      id: uuidv4(),
      isCancelable,
      url,
      cancelTokenSource,
    };
    this.pendingReqs.push(cancleTokenPayload);
    try {
      let response: any;

      if (body) {
        if (
          body instanceof FormData &&
          (body?.get("file[]") || body?.get("file"))
        ) {
          headers["Content-Type"] = "multipart/form-data";
        }

        response = await axios({
          method,
          url,
          headers,
          data: body,
          cancelToken: cancelTokenSource.token,
        });
      } else {
        response = await axios({
          method,
          url,
          headers,
          data: {},
          cancelToken: cancelTokenSource.token,
        });
      }

      const data = response.data;
      if (typeof data === "object") {
        data.code = response?.status;
      }

      if (response.status === 401) {
        handleUnAutorized();
      } else {
        return { ...data, codeStatus: response?.status };
      }
      throw new Error(data.message || data.detail || "Request failed");
    } catch (error: any) {
      const { response } = error;
      if (error.code === "ERR_CANCELED") return;
      console.error(error);
      if (response?.status === 401) {
        handleUnAutorized();
      } else {
        throw new Error(
          response?.data?.message || response?.data?.detail || "Request failed"
        );
      }
    } finally {
      this.pendingReqs = this.pendingReqs.filter(
        (pr) => pr.id !== cancleTokenPayload.id
      );
    }
  }

  async get(endpoint: string) {
    return this.request(endpoint, "GET");
  }

  async post(endpoint: string, body: object) {
    return this.request(endpoint, "POST", body);
  }

  async patch(endpoint: string, body?: object) {
    return this.request(endpoint, "PATCH", body);
  }

  async put(endpoint: string, body?: object) {
    return this.request(endpoint, "PUT", body);
  }

  async delete(endpoint: string) {
    return this.request(endpoint, "DELETE");
  }

  async downloadFile(endpoint: string, method: string) {
    const url = this.baseUrl + endpoint;
    let headers = {
      "Content-Type": "application/json",
    };

    const token = Cookie.get("token");
    headers = setHeaderInCondition(
      headers,
      token,
      "Authorization",
      `Bearer ${token}`
    );

    try {
      const response = await axios({
        method,
        url,
        headers,
        responseType: "blob",
      });

      if (response.status === 401) {
        handleUnAutorized();
      } else {
        return response;
      }
    } catch (error: any) {
      const { response } = error;
      console.error(error);
      if (response && response.status === 401) {
        handleUnAutorized();
      }
    }
  }
}

export default APIManager;

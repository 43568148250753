import PageWrapper from "../components/Layout/PageWrapper";
import Grid from "../components/Layout/Grid";
import Calendar from "../components/GoogleCalendar";
import { useNavigate } from "react-router-dom";

export default function AgendaCalendar() {
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const APIKey = process.env.REACT_APP_GOOGLE_API_KEY;

  return (
    <PageWrapper>
      <h2>Prise de rendez-vous</h2>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className="calendar-wrapper">
            {/* @ts-ignore */}
            <Calendar clientId={clientId} APIKey={APIKey} navigate={navigate} showFooter={false} />
          </div>
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

import Icon from "./Icon";

interface ButtonLinkProps {
  text: string;
  icon?: string;
  onClick: () => void;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ text, icon, onClick }) => {
  return (
    <div className="btn-link">
      {icon && <Icon icon={icon} size={18} />}
      <p className="btn-action" onClick={onClick}>
        {text}
      </p>
    </div>
  );
}

export default ButtonLink;

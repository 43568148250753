import React, { useEffect, useRef, useState } from "react";
import { Field } from "../../components/Form";
import Icon from "../Icon";
import useTrigger from "../../hooks/useTrigger";
import Tooltip from "../Tooltip";

interface TextFieldProps {
  field: Field;
  formik: any;
}

const TextField: React.FC<TextFieldProps> = ({ field, formik }) => {
  const { triggerModel } = useTrigger();
  const inputRef = useRef<HTMLInputElement>();

  let fieldToTrigger = null;
  if (field.trigger) {
    fieldToTrigger = formik.values[field.trigger.name];
  }
  const [fieldValue, setFieldValue] = useState(
    formik.values[field.name] || field.defaultValue || ""
  );

  useEffect(() => {
    if (field?.trigger?.name && field?.trigger?.name !== "") {
      if (
        formik.values[field?.trigger?.name] &&
        formik.values[field?.trigger?.name] !== ""
      ) {
        const newValue = field.trigger.triggerFunc(
          formik.values[field.trigger.name]
        );
        formik.setFieldValue(field.name, newValue);
      }
    }
  }, [fieldToTrigger]);

  let fValue = null,
    sValue = null;
  if (field.operation) {
    fValue = formik.values[field.operation.fields[0]];
    sValue = formik.values[field.operation.fields[1]];
  }

  useEffect(() => {
    if (field.operation) {
      let updatedFValue = formik.values[field.operation.fields[0]];
      let updatedSValue = formik.values[field.operation.fields[1]];
      if (updatedFValue === "" || !updatedFValue) {
        updatedFValue = 0;
      }
      if (updatedSValue === "" || !updatedSValue) {
        updatedSValue = 0;
      }
      if (isNaN(updatedFValue) || isNaN(updatedSValue)) {
        triggerModel("error", "Veuillez entrer un number valeur");
        formik.setFieldValue(field.operation.targetField, "invalide");
        return;
      }
      const parsedFValue: number = parseInt(updatedFValue);
      const parsedSValue: number = parseInt(updatedSValue);
      const newValue = parsedFValue - parsedSValue;
      formik.setFieldValue(field.operation.targetField, newValue.toString());
    }
  }, [fValue, sValue]);

  useEffect(() => {
    formik.setFieldValue(field.name, fieldValue);
  }, []);

  useEffect(() => {
    setFieldValue(formik.values[field.name]);
    formik.validateForm();
  }, [formik.values[field.name]]);

  useEffect(() => {
    if (!fieldValue && inputRef?.current) {
      inputRef.current.value = "";
    }
  }, [fieldValue]);

  return (
    <div
      key={field.name}
      className={`form-group${field.isHidden ? " form-group-is-hidden" : ""}`}
    >
      <div className="label-wrapper">
        <label htmlFor={field.name}>
          {field.label}
          {field.tooltip ? (
            <Tooltip text={field.tooltip}>
              <span className="tooltip-icon">i</span>
            </Tooltip>
          ) : null}
        </label>
        {field.action ? (
          <span className="link-action" onClick={field.action.handler}>
            {field.action.name}
          </span>
        ) : null}
      </div>
      <div className={`input-wrapper ${field.prefix ? "prefix" : ""}`}>
        {field.prefix && <span className="prefix-content">{field.prefix}</span>}
        <input
          ref={inputRef}
          className="form-control"
          readOnly={field.isReadOnly}
          type={field.type}
          id={field.name}
          {...formik.getFieldProps(field.name)}
          // value={fieldValue}
        />
        {field.isEditable ? (
          <span className="editable-icon">
            <Icon icon="note-pencil" size={15} />
          </span>
        ) : null}
      </div>

      {formik.touched[field.name] && formik.errors[field.name] && (
        <div className="form-group-error">{formik.errors[field.name]}</div>
      )}
    </div>
  );
};

export default TextField;

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "./Icon";
import moment from "moment";
import useTrigger from "../hooks/useTrigger";

export default function List({
  title,
  otherData,
  items,
  navigateTo,
  navigateToParam,
  listStyle,
  showDropdown = false,
  useStateData = false,
}: {
  title: string;
  otherData?: any;
  items: any[];
  navigateTo: string;
  navigateToParam?: string;
  listStyle?: string;
  showDropdown?: boolean;
  useStateData?: boolean;
}) {
  const location = useLocation();
  const breadcrumb = location?.state?.breadcrumb || [];

  const [expandedItemIndex, setExpandedItemIndex] = useState<number | null>(
    null
  );

  const handleDropClick = (event: any, index: number) => {
    event.preventDefault();
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const getStatusClassName = (status: any) => {
    switch (status) {
      case 0:
      case null:
        return "list-dot-red";
      case 1:
        return "list-dot-orange";
      case 2:
        return "list-dot-green";
      default:
        return "list-dot-black";
    }
  };

  const { triggerModel } = useTrigger();

  useEffect(() => {
    setExpandedItemIndex(null);
  }, [items]);

  return (
    <div className={`list ${listStyle ? "list-" + listStyle : ""}`}>
      <div className="list-head">
        <h3 className="list-title">{`${title} ${
          otherData?.total ? `(${otherData.total})` : ""
        }`}</h3>
        {otherData && (
          <div className="list-otherdata">
            <span className="mark-green">{otherData?.green || 0}</span>
            <span className="mark-orange">{otherData?.orange || 0}</span>
            <span className="mark-red">{otherData?.red || 0}</span>
          </div>
        )}
      </div>
      <div className="breadcrumb-wrapper">
        {location?.state?.breadcrumb && (
          <ul className="list-breadcrumb">
            {location?.state?.breadcrumb?.map(
              (item: { name: string; id: string }, i: number) => (
                <li className="list-breadcrumb-item">
                  <Link
                    to={`/list-structures?id=${item.id}`}
                    state={{
                      breadcrumb: breadcrumb.slice(0, i + 1),
                      contactData: item,
                      navigateToParam: navigateToParam,
                    }}
                  >
                    {item.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        )}
      </div>
      <div className="list-items-wrapper">
        {items && items.length > 0 ? (
          <ul className="list-items">
            {items.map((item, i) => (
              <li key={i}>
                <Link
                  to={
                    useStateData
                      ? navigateTo
                      : `${navigateTo}?id=${item.id}&type=structure`
                  }
                  state={
                    useStateData
                      ? {
                          contactData: item,
                          navigateToParam: navigateToParam,
                        }
                      : null
                  }
                >
                  <div className="main-infos">
                    <div className="left">
                      <p>{item.name}</p>
                    </div>
                    {item.createdAt && item.showCreated && (
                      <p className="date">
                        Crée le {moment(item.createdAt).format("DD/MM/YYYY")}
                      </p>
                    )}
                    <div className="right">
                      {showDropdown && (
                        <>
                          <div
                            className={`dropdown-arrow ${
                              expandedItemIndex === i ? "expanded" : ""
                            }`}
                            onClick={(event) => handleDropClick(event, i)}
                          >
                            <Icon icon="arrow-down" size={14} />
                          </div>
                          <div
                            className={`list-dot ${getStatusClassName(
                              item.status
                            )}`}
                          ></div>
                        </>
                      )}
                      <ul className="actions">
                        <li>
                          <p>Voir</p>
                          <Icon icon="eye" size={16} />
                        </li>
                        {typeof item.hasChilds === "number" && (
                          <li
                            className={`${!item.hasChilds ? "disabled" : ""}`}
                          >
                            <Link
                              to={`/list-structures?id=${item.id}`}
                              state={{ breadcrumb: [...breadcrumb, item] }}
                              onClick={(e) => {
                                if (item.hasChilds === 0) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  triggerModel(
                                    "error",
                                    "Pas de sous-structures"
                                  );
                                }
                              }}
                            >
                              <Icon icon="list" size={16} />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {showDropdown && expandedItemIndex === i && (
                    <div
                      className={`additional-infos ${
                        expandedItemIndex === i ? "expanded" : ""
                      }`}
                    >
                      <div className="infos-col">
                        <b>CA net: </b> {item.ca || 0}
                      </div>
                      <div className="infos-col">
                        <b>Montant financé :</b> {item.mnt || 0}
                      </div>
                      <div className="infos-col">
                        <b>Dossiers financés : </b> {item.countfolders || 0}
                      </div>
                      <div className="infos-col">
                        <b>Nombre d’APA : </b> {item.coutapas || 0}
                      </div>
                    </div>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="message">{title} non trouvé</p>
        )}
      </div>
    </div>
  );
}

import Cookie from "js-cookie";
import axios, { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import {startLoading, stopLoading} from '../state/slices/appSlice';
import CFCApi from '../apis';


const useDownloads = () => {
  const dispatch = useDispatch();

  const exportAll = () => {
    const url = `${CFCApi.baseUrl}/users/export-all`;
    const token = Cookie.get("token");
      dispatch(startLoading());
    axios
      .request({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        url,
        responseType: "blob",
      })
      .then(async (response: AxiosResponse<Blob>) => {
        const blobUrl = window.URL.createObjectURL(response.data);

        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "all-data.xls";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(stopLoading());
      })
  }
  return {
    exportAll
  }
};

export default useDownloads;
